import React from 'react'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import Banner from '../About/Banner'
import Yogasection from './Yogasection'
import { useEffect } from 'react'

function Services() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])
  return (
    <div>
      <>
        {/* Page Wrapper */}
        <div className="page-wrapper">
          {/* Header Main Area */}
          <Header />
          {/* Header Main Area End Here */}
          {/* Title Bar */}
          <Banner />
          {/* Title Bar End*/}
          {/* Page Content */}
          <div className="page-content">
            {/* Service Details */}
            <Yogasection />
            {/* Service Details End */}
          </div>
          {/* Page Content End */}
          
          {/* footer */}
          <Footer />
          {/* footer End */}
        </div>
        {/* Page Wrapper End */}
        {/* Search Box Start Here */}
        <div className="pbmit-search-overlay">
          <div className="pbmit-icon-close">
            <svg
              className="qodef-svg--close qodef-m"
              xmlns="http://www.w3.org/2000/svg"
              width="28.163"
              height="28.163"
              viewBox="0 0 26.163 26.163"
            >
              <rect width={36} height={1} transform="translate(0.707) rotate(45)" />
              <rect
                width={36}
                height={1}
                transform="translate(0 25.456) rotate(-45)"
              />
            </svg>
          </div>
          <div className="pbmit-search-outer">
            <form className="pbmit-site-searchform">
              <input
                type="search"
                className="form-control field searchform-s"
                name="s"
                placeholder="Search …"
              />
              <button type="submit" />
            </form>
          </div>
        </div>
        {/* Search Box End Here */}
        {/* Scroll To Top */}
        <div
          className="pbmit-progress"
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          style={{
            position: 'fixed',
            bottom: '30px',
            right: '30px',
            width: '50px',
            height: '50px',
            backgroundColor: '#b4a9c9',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            zIndex: 1000,
            opacity: 0.7,
          }}
          onMouseOver={(e) => e.currentTarget.style.opacity = 1}
          onMouseOut={(e) => e.currentTarget.style.opacity = 0.7}
        >
          <svg
            className="pbmit-progress-circle svg-content"
            width="100%"
            height="100%"
            viewBox="-1 -1 102 102"
          >
            <path
              d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
              style={{
                fill: 'none',
                stroke: 'white',
                strokeWidth: 5,
              }}
            />
          </svg>
          {/* Arrow SVG */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="white"
            viewBox="0 0 24 24"
            style={{
              position: 'absolute',
              width: '20px',
              height: '20px',
            }}
          >
            <path
              d="M12 19c-.55 0-1-.45-1-1V8.41L6.71 12.7a.996.996 0 1 1-1.41-1.41l6-6a.996.996 0 0 1 1.41 0l6 6a.996.996 0 1 1-1.41 1.41L13 8.41V18c0 .55-.45 1-1 1z"
            />
          </svg>
        </div>
        {/* Scroll To Top End */}
      </>

    </div>
  )
}

export default Services
