import React, { useRef, useState } from "react";
import { FaPlay } from "react-icons/fa";
import { useParams } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
// import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import ModalVideo from "react-modal-video";
import "./style.css";

function Card(props) {
  const { lang = "en" } = useParams();
  const [videoOpen, setVideoOpen] = useState(false);
  const videoRef = useRef(null);

  const handleOutsideClick = (e) => {
    if (e.target.classList.contains("modal-overlay")) {
      setVideoOpen(false);
    }
  };

  return (
    <div
      className={`TestimonialHOmepage23Card ${
        lang == "ar" ? "r_dir" : "l_dir"
      } ${props?.currentSlide == props?.index ? "" : "testLidk"}`}
      {...props}
      style={{ position: "relative" }}
    >
      <div
        className={`cardFlight ${
          props?.currentSlide == props?.index ? "cardActive" : ""
        }`}
        style={{
          backgroundI: `url(${props?.thumb}) !important`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "top",
          backgroundColor: props?.thumb ? "transparent" : "#f0f0f0",
        }}
      >
        {/* <img src={props?.image} alt={props?.title} className="cardImg" /> */}
        <h3>{props?.title || "Awsome Experience"}</h3>
        <p className="para">{props?.message}</p>

        {/* <div className="reviews">
          <p className="rating">
            <ReactStars
              count={5}
              edit={false}
              size={24}
              isHalf={true}
              value={5.0}
              emptyIcon={<AiFillStar />}
              halfIcon={<AiOutlineStar />}
              fullIcon={<AiFillStar />}
              activeColor="#7ab342"
              color="#e4e2e2"
            />
          </p>
          <p className="reviewInfo">(5.0 stars)</p>
        </div> */}
        <div className="players mt-1">
          <p className="para">{props?.name}</p>

          <div
            className="PlayButton"
            onClick={() => {
              // if (!props?.videoUrl) {
              //   return;
              // }
              setVideoOpen(!videoOpen);
            }}
          >
            <FaPlay size={16} />
          </div>
        </div>
      </div>
      {/* <ModalVideo
        channel={props?.image}
        isOpen={videoOpen}
        videoId={props?.videoUrl}
        onClose={() => setVideoOpen(false)}
      /> */}
      {videoOpen && (
        <div
          className="modal-overlay"
          onClick={handleOutsideClick}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <div
            style={{
              rotate: "45deg",
              position: "absolute",
              top: "5%",
              right:"10%",
              transform: "translate(-50%, -50%)",
              color: "#fff",
              fontSize: "50px",
              zIndex: 1100,
            }}
            onClick={() => setVideoOpen(false)}
          >
            +
          </div>
          <div
            className="modal-content"
            style={{
              position: "relative",
              backgroundColor: "#fff",
              borderRadius: "8px",
              overflow: "hidden",
              maxWidth: "80%",
              maxHeight: "80%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <video
              src={props?.image}
              controls
              autoPlay
              style={{
                width: "100%",
                height: "350px",
                zIndex: 100,
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Card;
