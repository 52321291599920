import React, { useRef, useState } from "react";
import Slider from "react-slick";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { Col, Container, Row } from "react-bootstrap";
import Card from "../../functions/card";
import DrSliderArrows from "../../functions/sliders";
import imgt1 from "../../Homepage/testimonial.jpg";
// testimonial
import testimonial1 from "../../Homepage/Our Testimonials1.webp";
import testimonial2 from "../../Homepage/Our Testimonials2.webp";
import testimonial3 from "../../Homepage/Our Testimonials3.webp";
import testimonial4 from "../../Homepage/Our Testimonials4.webp";
import Image1 from "../../Homepage/Our Testimonials1.webp"

import "./style.css";

function Index(props) {
  const { content } = props;

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 800,
    lazyLoad: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    swipeToSlide: true,
    beforeChange: (current, next) => {
      setCurrentSlide(next);
    },
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 776,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  let cardData = [
    {
      id: 1,
      thumb: Image1,
      date: "January 31, 2024",
      title: "Amazing journey!",
      details:
        "I had an exciting sky journey. The pilot was respectful, experienced, and funny, making the balloon ride simple and enjoyable. I would recommend this company; you can trust them.",
      author: "Nzar",
      videoUrl: "",
    },
    {
      id: 2,
      thumb: testimonial2,
      date: "December 4, 2023",
      title: "Best Experience",
      details:
        "This was one of my favorite experiences while in Dubai. Everyone was very nice and accommodating. They enjoyed taking pictures and videos for us to capture our memories. I would 10/10 recommend this place again! All of the staff were excellent.",
      author: "Danielle J",
      videoUrl: "",
    },
    {
      id: 3,
      thumb: testimonial3,
      date: "December 4, 2023",
      title: "Exciting experience with Maha Balloons",
      details:
        "It was a wonderful experience with Maha’s hot air balloon. We got to see the sunrise by flying at the highest altitude possible of 4000 ft. Excellent and seamless customer service from Regina and great hospitality from Julie. I recommend Maha Balloons if you are interested in experiencing a hot air balloon flight.",
      author: "Suhail Ismail",
      videoUrl: "1018644101",
      channel: "vimeo",
    },
    {
      id: 4,
      thumb: testimonial4,
      date: "December 3, 2023",
      title: "Must-Visit",
      details: `Excellent service Nice and super friendly staff\n
Amazing pilots\n
Excellent service with a nice and super friendly staff. The pilots were amazing! Special thanks to Ms. Julie and Ms. Regina for making our trip memorable. You guys are amazing!
`,
      author: "Shweta Dubey",
      videoUrl: "1018656678",
      channel: "vimeo",
    },
    {
      id: 5,
      thumb: testimonial1,
      date: "December 3, 2023",
      title: "Awesome experience",
      details:
        "It was a truly fantastic experience. The pilot, Mr. Kundal, and the guide, Ms. Regina, were professional, super kind, and dedicated. I highly recommend these two to you. Thank you for creating an unforgettable experience.",
      author: "Jay",
      videoUrl: "",
    },
  ];
  const sliderRef = useRef();
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
      setCurrentSlide((prevSlide) => prevSlide + 1);
    }
  };
  const previousSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
      setCurrentSlide((prevSlide) => prevSlide - 1);

    }
  };

  const PrevArrow = () => (
    <button
      className="slider_custom_arrows"
      onClick={previousSlide}
      disabled={currentSlide === 0}
      style={{ borderRadius: "20%" }}
    >
      <FaAngleLeft fontSize={"24px"} />
    </button>
  );

  const NextArrow = () => (
    <button
      className="slider_custom_arrows ms-3"
      onClick={nextSlide}
      disabled={currentSlide >= cardData?.length - 1}
      style={{ borderRadius: "20%" }}
    >
      <FaAngleRight fontSize={"24px"} />
    </button>
  );
  return (
    <div className="TestimonialHOmepage23 py-40">
      <Container>
        <div className="pbmit-heading-subheading text-white animation-style2">
          <h4 className="pbmit-subtitle">Testimonial</h4>
        </div>
        <Row>
          <Col className="pbmit-title pbmit-title1" md={12} lg={8}>
            <h2 className="pbmit-title" style={{ color: "black" }}>Clients feedback</h2>
          </Col>
          <Col className="sliderArrows" md={12} lg={4}>
            <div className="sliderArrows slider_pd-end">
              <DrSliderArrows
                prevArrow={<PrevArrow />}
                nextArrow={<NextArrow />}
              />
            </div>
          </Col>
        </Row>

        <div className="SliderWrapper3 mt-3 mt-md-5">
          {/* <div className="arrowslic22dernext">
            <NextArrow />
          </div>
          <div className="arrowslic22derprev">
            <PrevArrow />
          </div> */}
          {content?.length > 0 ? (
            <>
              <Slider ref={sliderRef} {...settings}>
                {content?.map((item, i) => (
                  <Card {...item} currentSlide={currentSlide} index={i} />
                ))}
              </Slider>
            </>
          ) : (
            <>
              <Slider ref={sliderRef} {...settings}>
                {cardData?.map((item, i) => (
                  <Card {...item} currentSlide={currentSlide} index={i} />
                ))}
              </Slider>
            </>
          )}
        </div>
        <div className="d-flex justify-content-between justify-content-lg-end align-content-center  mt-5">
          {content?.length > 0 ? (
            <div className="dots">
              {content?.map((item, i) => (
                <div
                  className={`dot ${currentSlide == i ? "active" : null}`}
                  onClick={() => {
                    if (sliderRef.current) {
                      console.log("🚀 ~ Index ~ sliderRef:", sliderRef, i);

                      sliderRef.current.slickGoTo(i);
                      setCurrentSlide(i);
                    }
                  }}
                ></div>
              ))}
            </div>
          ) : (
            <div className="dots">
              {cardData?.map((item, i) => (
                <div
                  className={`dot ${currentSlide == i ? "active" : null}`}
                  onClick={() => {
                    if (sliderRef.current) {
                      console.log("🚀 ~ Index ~ sliderRef:", sliderRef, i);

                      sliderRef.current.slickGoTo(i);
                      setCurrentSlide(i);
                    }
                  }}
                ></div>
              ))}
            </div>
          )}
          <div className="sliderArrows sliderMobile">
            {/* <DrSliderArrows
              prevArrow={<PrevArrow />}
              nextArrow={<NextArrow />}
            /> */}
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Index;
