import React, { useEffect, useState } from "react";
import Header from "../Layout/Header";
import { baseurl } from "../../api";
import axios from "axios";

const Banner = () => {
  const [videoUrl, setVideoUrl] = useState("");

  useEffect(() => {
    axios
      .get(`${baseurl}/api/getimage`)
      .then((response) => {
        // console.log("response" , response)
        setVideoUrl(response?.data?.[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  console.log("videoUrl", videoUrl?.carouselImages);

  const bannerStyles = {
    position: "relative",
    width: "100%",
    height: "90vh",
    overflow: "hidden",
  };

  const videoStyles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    objectFit: "cover",
  };

  const spinnerStyles = {
    position: "relative",
    width: "100%",
    height: "90vh",
    display: "flex",
    justifyContent : "center",
    alignItems : "center"
  }

  return (
    <div className="page-wrapper">
      <header className="site-header header-style-3">
        <Header />
        <div className="pbmit-slider-social">
          <ul className="pbmit-social-links">
            <li className="pbmit-social-li pbmit-social-facebook">
              <a title="Facebook" href="#" target="_blank">
                <span>
                  <i className="pbmit-base-icon-facebook-f" />
                </span>
              </a>
            </li>
            <li className="pbmit-social-li pbmit-social-twitter">
              <a title="Twitter" href="#" target="_blank">
                <span>
                  <i className="pbmit-base-icon-twitter-2" />
                </span>
              </a>
            </li>
            <li className="pbmit-social-li pbmit-social-linkedin">
              <a title="LinkedIn" href="#" target="_blank">
                <span>
                  <i className="pbmit-base-icon-linkedin-in" />
                </span>
              </a>
            </li>
            <li className="pbmit-social-li pbmit-social-instagram">
              <a title="Instagram" href="#" target="_blank">
                <span>
                  <i className="pbmit-base-icon-instagram" />
                </span>
              </a>
            </li>
          </ul>
        </div>
        {videoUrl ? (
          <div style={bannerStyles}>
            <video style={videoStyles} autoPlay muted loop playsInline>
              <source src={videoUrl?.carouselImages} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        ) : (
          <div style={spinnerStyles}>
            <div class={`spinner-border`} role={videoUrl}>
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </header>
    </div>
  );
};

export default Banner;
