import React, { useState, useEffect } from 'react';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import Banner from '../About/Banner';
import axios from 'axios';
import { baseurl } from '../../api';
import { useNavigate } from 'react-router-dom'; // useNavigate instead of useHistory

function Event() {
    const [data, setData] = useState([]); // State for storing event data
    const [data1, setData1] = useState([]); // State for storing retreat data
    const navigate = useNavigate(); // useNavigate hook for navigation

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        // Fetch events data
        axios.get(`${baseurl}/api/events/all`)
            .then(response => {
                setData(response.data); // Store events data
                console.log(response.data);
            })
            .catch(error => {
                console.error("Error fetching events:", error);
            });

        // Fetch retreats data
        axios.get(`${baseurl}/api/retreats/all`)
            .then(response => {
                setData1(response.data); // Store retreats data
                console.log(response.data);
            })
            .catch(error => {
                console.error("Error fetching retreats:", error);
            });
    }, []);

    // Redirect to event details
    // const history = useHistory();
    const handleRedirectEvent = (id) => {
        // console.log(`Navigating to /event/${id}`);
        // history.push(`/event/${id}`);
        navigate(`/event/${id}`);
    };
    // Redirect to retreat details
    const handleRedirectRetreat = (id) => {
        navigate(`/retreat/${id}`); // Navigate to retreat details using the ID
    }

    return (
        <div>
            <div className="page-wrapper">
                {/* Header Main Area */}
                <Header />
                {/* Header Main Area End Here */}

                {/* Title Bar */}
                <Banner />
                {/* Title Bar End */}

                {/* Schedule start */}
                <section className="section-md event_boxes">
                    <div style={{ textAlign: 'center', marginTop: '-20px' }} className="pbmit-event-details">
                        <div className="pbmit-event-title">
                            <h1 className="pbmit-heading-text">
                                <a>Schedule</a>
                            </h1>
                        </div>
                    </div>
                    <div style={{ marginTop: '20px' }} className="container">
                        <div className="row">
                            {data.map(event => (
                                <div className="col-md-4" key={event._id}>
                                    <div className="pbmit-ele-event_listing" onClick={() => handleRedirectEvent(event._id)}>
                                        <div className="pbmit-event-layout-wrapper">
                                            <div className="event_listing event-type-skin-quality">
                                                <div className="pbmit-event-action-url event-style-color skin-quality">
                                                    <div className="pbmit-event-banner">
                                                        <div className="pbmit-event-banner-img" style={{ backgroundImage: `url(${event.image})` }} onClick={() => handleRedirectEvent()}>
                                                            <div className="pbmit-event-type">
                                                                <a href="#" style={{ backgroundColorcolor: 'red' }}>
                                                                    <span className="pbmit-event-type-text event-type skin-quality" >{event.heading}</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <a className="pbmit-button" >
                                                            <span className="pbmit-button-icon-wrapper">
                                                                <span className="pbmit-button-icon">
                                                                    <i className="pbmit-base-icon-black-arrow-1" />
                                                                </span>
                                                            </span>
                                                        </a>
                                                        <a className="pbmit-link" />
                                                    </div>
                                                    <div className="pbmit-event-infomation">
                                                        <div className="pbmit-event-date">
                                                            <div className="pbmit-event-date-type">
                                                                <div className="pbmit-from-date">
                                                                    <div className="pbmit-date" onClick={() => handleRedirectEvent(event._id)}>{new Date(event.date).getDate().toString().padStart(2, '0')}</div>
                                                                    <div className="pbmit-month">
                                                                        {new Date(event.date).toLocaleString('default', { month: 'short' })}
                                                                    </div>
                                                                    <div className="pbmit-year">{new Date(event.date).getFullYear()}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="pbmit-event-details">
                                                            <div className="pbmit-event-title">
                                                                <h3 className="pbmit-heading-text">
                                                                    <a onClick={() => handleRedirectEvent(event._id)}>{event.tagline}</a>
                                                                </h3>
                                                            </div>
                                                            <div className="pbmit-event-meta d-flex align-items-center">
                                                                <div className="pbmit-event-meta-line pbmit-event-time d-flex">
                                                                    <i className="pbmit-base-icon-time" />
                                                                    <div className="pbmit-event-meta-time">{event.session} min / Session</div>
                                                                </div>
                                                                <div className="pbmit-event-meta-line pbmit-event-registration-date d-flex">
                                                                    <i className="pbmit-base-icon-calendar-3" />
                                                                    <span className="pbmit-event-date-text">
                                                                        {new Date(event.date).getDate().toString().padStart(2, '0')} {/* Day */}
                                                                        {new Date(event.date).toLocaleString('default', { month: 'short' })} {/* Month */}
                                                                        {new Date(event.date).getFullYear()} {/* Year */}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="pbmit-event-price">
                                                                <div className="pbmit-event-meta-price" onClick={() => handleRedirectEvent(event._id)}>{event.price} / Month</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pbmit-event-arrow-link">
                                                    <a className="event-style-color skin-quality" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {/* Retreat start */}
                    <div style={{ textAlign: 'center', marginTop: '-20px' }} className="pbmit-event-details">
                        <div className="pbmit-event-title">
                            <h1 className="pbmit-heading-text">
                                <a>Retreat</a>
                            </h1>
                        </div>
                    </div>
                    <div style={{ marginTop: '20px' }} className="container">
                        <div className="row">
                            {data1.map(event => (
                                <div className="col-md-4" key={event._id}>
                                    <div className="pbmit-ele-event_listing" onClick={() => handleRedirectRetreat(event._id)}>
                                        <div className="pbmit-event-layout-wrapper" onClick={() => handleRedirectRetreat(event._id)}>
                                            <div className="event_listing event-type-skin-quality">
                                                <div className="pbmit-event-action-url event-style-color skin-quality">
                                                    <div className="pbmit-event-banner">
                                                        <div className="pbmit-event-banner-img" style={{ backgroundImage: `url(${event.image})` }}>
                                                            <div className="pbmit-event-type">
                                                                <a href="#">
                                                                    <span className="pbmit-event-type-text event-type skin-quality">{event.heading}</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <a className="pbmit-button" onClick={() => handleRedirectRetreat(event._id)}>
                                                            <span className="pbmit-button-icon-wrapper">
                                                                <span className="pbmit-button-icon">
                                                                    <i className="pbmit-base-icon-black-arrow-1" />
                                                                </span>
                                                            </span>
                                                        </a>
                                                        <a className="pbmit-link" />
                                                    </div>
                                                    <div className="pbmit-event-infomation">
                                                        <div className="pbmit-event-date">
                                                            <div className="pbmit-event-date-type">
                                                                <div className="pbmit-from-date">
                                                                    <div className="pbmit-date">{new Date(event.date).getDate().toString().padStart(2, '0')}</div>
                                                                    <div className="pbmit-month">
                                                                        {new Date(event.date).toLocaleString('default', { month: 'short' })}
                                                                    </div>
                                                                    <div className="pbmit-year">{new Date(event.date).getFullYear()}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="pbmit-event-details">
                                                            <div className="pbmit-event-title">
                                                                <h3 className="pbmit-heading-text">
                                                                    <a>{event.tagline}</a>
                                                                </h3>
                                                            </div>
                                                            <div className="pbmit-event-meta d-flex align-items-center">
                                                                <div className="pbmit-event-meta-line pbmit-event-time d-flex">
                                                                    <i className="pbmit-base-icon-time" />
                                                                    <div className="pbmit-event-meta-time">{event.days} Days</div>
                                                                </div>
                                                                <div className="pbmit-event-meta-line pbmit-event-registration-date d-flex">
                                                                    <i className="pbmit-base-icon-calendar-3" />
                                                                    <span className="pbmit-event-date-text">
                                                                        {new Date(event.date).getDate().toString().padStart(2, '0')} {/* Day */}
                                                                        {new Date(event.date).toLocaleString('default', { month: 'short' })} {/* Month */}
                                                                        {new Date(event.date).getFullYear()} {/* Year */}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="pbmit-event-price">
                                                                <div className="pbmit-event-meta-price">{event.price} / Retreat</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pbmit-event-arrow-link">
                                                    <a className="event-style-color skin-quality" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
                {/* Footer */}
                <Footer />
                {/* Footer End */}
            </div>
        </div>
    );
}

export default Event;